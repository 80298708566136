<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" style="display: flex; justify-content: center">
            <h1>Lüftungsplanung</h1>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1" cols="12">
            <v-carousel cycle class="ma-0">
              <v-carousel-item
                src="../assets/lueftungsthumb.png"
              />
            </v-carousel>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Leistungen auf einen Blick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Planung von: <br />
                <ul>
                  <li>
                    Zu- und Abluftanlagen mit und ohne Wärmerückgewinnung
                  </li>
                  <li>Küchenabluftsysteme</li>
                  <li>Kontrollierte Wohnraumlüftungen</li>
                  <li>Dezentrale und zentrale Lüftungssysteme</li>
                  <li>Zukunftsorientierte Ionisationstechnik</li>
                  <li>Industrielle Lüftungsanalgen</li>
                </ul>
                <br />
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine
                Anfrage über das <a @click="toKontakt()">Kontaktformular</a>.
                Wir werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Lüftungsplanung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Lüftungsplanung bezieht sich auf die Entwicklung und
                Gestaltung eines Systems zur kontrollierten Luftbewegung in
                einem Gebäude. Es handelt sich um einen wichtigen Aspekt des
                Gebäude- und Anlagenbaus, der sicherstellt, dass eine
                angemessene Belüftung, Temperatur- und Feuchtigkeitskontrolle
                sowie die Reduzierung von Schadstoffen und Gerüchen
                gewährleistet sind.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Durchführung der Lüftungsplanung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Eine effektive Lüftungsplanung umfasst mehrere Schritte. <br> <br>
                Zunächst werden die Anforderungen und Bedürfnisse des Gebäudes
                und seiner Nutzer analysiert. Dies beinhaltet Aspekte wie die
                Raumgröße, die Nutzung der Räume, die Anzahl der Personen und
                die Art der Aktivitäten, die in den Räumen stattfinden.
                <br> <br>Basierend auf diesen Informationen wird ein Lüftungskonzept
                entwickelt. Dabei werden Faktoren wie der erforderliche
                Luftaustausch, die Art der Lüftungsanlage, die Platzierung der
                Lüftungsöffnungen und die Kontrollmechanismen berücksichtigt. <br> <br>Es
                gibt verschiedene Arten von Lüftungssystemen, einschließlich
                natürlicher Lüftung (über Fenster oder Belüftungsöffnungen) und
                mechanischer Lüftung (mit Hilfe von Ventilatoren und
                Klimaanlagen). 
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Weitere Aspekte Lüftungsplanung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Bei der Planung der Lüftungsanlage werden auch
                energetische Aspekte berücksichtigt, um den Energieverbrauch zu
                optimieren und die Nachhaltigkeit zu fördern. Dies kann den
                Einsatz von Wärmerückgewinnungssystemen, intelligenten
                Steuerungen und energieeffizienten Ventilatoren umfassen. <br> <br>Nach
                Abschluss der Planung werden die spezifischen technischen
                Anforderungen für die Lüftungsanlage festgelegt, einschließlich
                der Auswahl der geeigneten Komponenten, Materialien und
                Installationstechniken. Dies beinhaltet auch die
                Berücksichtigung von Brandschutz- und Sicherheitsstandards sowie
                der Einhaltung der geltenden Vorschriften und Normen.<br> <br>
                Schließlich erfolgt die Installation der Lüftungsanlage gemäß
                den Plänen und Spezifikationen. Nach der Installation werden die
                Systeme getestet, um sicherzustellen, dass sie ordnungsgemäß
                funktionieren und den erforderlichen Luftstrom und die
                Luftqualität liefern. <br> <br>Insgesamt zielt die Lüftungsplanung darauf
                ab, ein komfortables und gesundes Raumklima zu schaffen, indem
                sie für eine angemessene Belüftung und Luftqualität sorgt und
                gleichzeitig den Energieverbrauch optimiert.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-5 d-flex justify-center">
          <v-btn @click="toHome()" style="color: white;" color="#000">Zurück zum Hauptmenü</v-btn>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/")
    },
    toKontakt() {
      this.$router.push("/kontakt")
    }
  }
}</script>
