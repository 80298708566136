<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" style="display: flex; justify-content: center">
            <h1>Heizungsplanung</h1>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1" cols="12">
            <v-carousel cycle class="ma-0">
              <v-carousel-item
                src="../assets/heizungthumb.png"
              />
            </v-carousel>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Leistungen auf einen Blick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <ul>
                  <li>
                    Individuelle und Erneuerbare Heizkonzepte
                  </li>
                  <li>
                    Industrielle Heizsysteme inkl. Wärmerückgewinnung
                  </li>
                  <li>
                    Regenerative Sonderlösungen wie Biomasse und Geothermie
                  </li>
                  <li>
                    Fern- und Nahwärmenetz
                  </li>
                  <li>
                    Warmwasser- und Elektroheizsysteme
                  </li>
                  <li>
                    Flächenheizsysteme und konventionelle Heizkörper
                    </li>
                </ul>
                <br>
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine Anfrage über das <a @click="toKontakt()">Kontaktformular</a>. Wir werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
          </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Was umfasst die Heizungsplanung?</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Heizungsplanung befasst sich mit der Gestaltung und
                Installation eines effizienten Heizungssystems in Gebäuden. Eine
                gute Heizungsplanung berücksichtigt verschiedene Faktoren wie
                die Größe des Gebäudes, den Wärmebedarf, die Energieeffizienz,
                die Kosten und Umweltauswirkungen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Wie wird die Heizungsplanung durchgeführt?</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Zu Beginn der Planung werden die Anforderungen und Bedürfnisse
                des Gebäudes ermittelt. Dazu gehören die Raumgrößen, die
                Isolierung, die Nutzung und die individuellen Präferenzen der
                Nutzer. Basierend auf diesen Informationen wird der
                Wärmebedarf des Gebäudes berechnet. <br />
                Anschließend erfolgt die Auswahl des Heizungssystems. Hierbei
                spielen verschiedene Faktoren eine Rolle, wie z.B. die
                verfügbaren Energieträger (z.B. Gas, Öl, Holz, Solar), die Vor-
                und Nachteile der verschiedenen Systeme, die Verfügbarkeit von
                Brennstoffen und die Umweltauswirkungen. <br /><br />
                Ziel ist es, ein System zu wählen, das effizient, kostengünstig
                und umweltfreundlich ist. Die Planung umfasst auch die Auslegung
                der Wärmeerzeugungsanlage (z.B. Heizkessel, Wärmepumpe), die
                Verteilung der Wärme (z.B. Heizkörper, Fußbodenheizung) und die
                Regelungstechnik. Dabei werden die Rohrleitungen, Pumpen,
                Ventile und Thermostate entsprechend dimensioniert, um eine
                gleichmäßige und effiziente Wärmeverteilung zu gewährleisten.
                <br />
                Die Heizungsplanung berücksichtigt auch die Integration
                erneuerbarer Energien, um den ökologischen Fußabdruck zu
                minimieren. Dies kann den Einsatz von Solarthermie, Wärmepumpen
                oder Biomasseanlagen beinhalten. <br /><br />
                Abschließend werden die Kosten für die Installation und den
                Betrieb der Heizungsanlage berücksichtigt. Dabei werden sowohl
                die Investitionskosten als auch die langfristigen Betriebskosten
                wie Brennstoffverbrauch und Wartung berücksichtigt. Eine
                sorgfältige Heizungsplanung ist entscheidend, um ein
                komfortables und energieeffizientes Heizungssystem zu schaffen,
                das den individuellen Anforderungen des Gebäudes gerecht wird.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-5 d-flex justify-center">
          <v-btn @click="toHome()" style="color: white" color="#000"
            >Zurück zum Hauptmenü</v-btn
          >
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Menue from "../components/TaetigkeitenUebersicht.vue";
export default {
  components: {
    Menue,
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toKontakt() {
      this.$router.push("/kontakt")
    }
  },
};
</script>
<style scoped>
.hoverRow:hover {
  background-color: #e0e0e0; /* Replace with your desired hover color */
}
</style>
