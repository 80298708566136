<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" style="display: flex; justify-content: center">
            <h1>Sanitärplanung</h1>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1" cols="12">
            <v-carousel cycle class="ma-0">
              <v-carousel-item
                src="../assets/sanitaerthumb.png"
              />
            </v-carousel>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Leistungen auf einen Blick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Planung von: <br>
                <ul>
                  <li>
                    Trinkwasser- und Schmutzwassersystemen
                  </li>
                  <li>
                    Gefährdungsanalysen im Bereich Trinkwasserhygiene
                  </li>
                  <li>
                    Grundleitungen inkl. Entwässerungsgesuch
                  </li>
                  <li>
                    Regenentwässerung inkl. Retentsions-, Versickerungs- und Nutzungsanlagen
                  </li>
                  <li>
                    Löschwassersysteme (Nass, Trocken und Nass-Trocken)
                  </li>
                  <li>
                    Hydranten- und Bewässerungssysteme
                    </li>
                </ul>
                <br>
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine Anfrage über das <a @click="toKontakt()">Kontaktformular</a>. Wir werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
          </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Was umfasst die Sanitärplanung?</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Sanitärplanung bezieht sich auf die Gestaltung und
                Installation von Sanitäranlagen in Gebäuden, um den Bedürfnissen
                der Benutzer gerecht zu werden. Eine effektive Sanitärplanung
                zielt darauf ab, die Funktionalität, Effizienz und
                Nachhaltigkeit der Sanitäranlagen zu maximieren. 
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Faktoren und Ziele der Sanitärplanung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Bei der Sanitärplanung werden verschiedene Faktoren
                berücksichtigt, wie beispielsweise die Anzahl der Benutzer, die
                Art des Gebäudes (z. B. Wohngebäude, Bürogebäude, öffentliche
                Einrichtungen) und die örtlichen Vorschriften und Normen. Ziel
                ist es, Sanitäranlagen zu schaffen, die den hygienischen
                Anforderungen entsprechen, den Wasserverbrauch minimieren und
                den Benutzern Komfort bieten.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Wie wird die Sanitärplanung durchgeführt?</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Planung umfasst die Auswahl und Positionierung verschiedener
                Sanitärgegenstände wie Toiletten, Waschbecken, Duschen und Urinale.
                Es werden auch Rohrleitungen, Abwassersysteme und
                Wasserversorgungsanlagen entworfen, um eine zuverlässige und
                effiziente Wasserversorgung und Abwasserentsorgung zu
                gewährleisten. <br><br>Die Sanitärplanung berücksichtigt auch die
                Barrierefreiheit und die Bedürfnisse von Menschen mit
                eingeschränkter Mobilität. Dies beinhaltet die Bereitstellung
                von barrierefreien Toiletten und Waschbecken, Haltegriffen und
                anderen Hilfsmitteln, um die Nutzung der Sanitäranlagen für alle
                Benutzer zu erleichtern. <br> <br>Darüber hinaus werden bei der
                Sanitärplanung auch energieeffiziente Technologien wie
                Wasserspararmaturen und wasserlose Urinale in Betracht gezogen,
                um den Wasserverbrauch zu reduzieren und die Nachhaltigkeit der
                Sanitäranlagen zu verbessern. <br><br>Insgesamt zielt eine gute
                Sanitärplanung darauf ab, hygienische, funktionale, effiziente
                und nachhaltige Sanitäranlagen zu schaffen, die den Bedürfnissen
                der Benutzer entsprechen und gleichzeitig den Umweltschutz
                fördern.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-5 d-flex justify-center">
          <v-btn @click="toHome()" style="color: white;" color="#000">Zurück zum Hauptmenü</v-btn>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/")
    },
    toKontakt() {
      this.$router.push("/kontakt")
    }
  }
}
</script>
<style>
.taetigkeitenHeadline{
  font-size: 15px;
  line-height: 20px;
}
</style>
